@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-marcellus: 'Mulish', sans-serif;
    --color-brand: #37D4AF;
    --color-text: #618B81;
    --color-white: #ffffff;
    --color-body: #000c15;
    --color-body-secondary: #001626;
    --color-heading: #0D332A;
    --color-shadow: rgba(46, 46, 46, 0.15)
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-marcellus);
    color: var(--color-white);
}
.embla {
    overflow: hidden;
}
.embla__container {
    display: flex;
}
.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
}
.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--color-brand);
    border: 1px solid white;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 8px;
    height: 8px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}
.embla__dot--selected {
    background-color: white;
}
html {
    overflow-x: hidden;
}
a {
    text-decoration: none;
}
ul,
li {
    list-style: none;
}
body {
    background-size: 24px 24px;
    background-image:
      linear-gradient(to right, #eaeaea 1px, transparent 1px),
      linear-gradient(to bottom, #eaeaea 1px, transparent 1px);
  }
.page__width {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
}
.animated_link {
    position: relative;
}
.animated_link:hover::after {
    width: 70%;
}
.animated_link::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 3px;
    background-color: var(--color-brand);
    transition: 0.3s all;
    width: 0;
}

@media screen and (max-width: 1100px) {
    .page__width {
        width: 90%;
    }
    .masonry{
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 8px;
    }
}

.masonry { /* Masonry container */
  -webkit-column-count: 4;
-moz-column-count:4;
column-count: 4;
-webkit-column-gap: 8px;
-moz-column-gap: 8px;
column-gap: 8px;
  padding: 0;
  -moz-column-gap: 8px;
  -webkit-column-gap: 8px;
  column-gap: 8px;
}
.masonry_item {
  display: inline-block;
  width: 100%;
-webkit-transition:1s ease all;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}
.masonry_item img{
    transition: all .2s ease-in-out;
}
.masonry_item img:hover{
    transform: scale(115%);
}